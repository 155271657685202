import { ReactComponent as MetaCornerLeft } from "./assets/Corner_left.svg";
import { ReactComponent as MetaCornerRight } from "./assets/Corner_right.svg";
import { ReactComponent as PcSpecialistLogo } from "./assets/pcspecialist_logo.svg";
import { ReactComponent as ScoreJewl } from "./assets/shape.svg";
import { ReactComponent as BorderLeftSide } from "./assets/border_left_side.svg";
import { ReactComponent as BorderRightSide } from "./assets/border_right_side.svg";
import ScoreCorner from "./assets/score_corner.svg";
import "./styles/index.scss";
import { cs2Weapons } from "@/consts/cs2Weapons";
import { Events, useLeaderboardData } from "@/hooks/useUserDataHook";
import { getTime } from "./../../helperFunctions/formatMsToTime";

export const PCSPECIALIST_ScoreBoard = () => {
  const { usersData, logs, reset } = useLeaderboardData(
    "PCSPECIALIST",
    "PCSPECIALIST"
  );

  const player1Score = usersData?.player1?.kills || 0;
  const player2Score = usersData?.player2?.kills || 0;

  return (
    <div className="pcspecialist">
      <div className="scoreboard">
        <div className="scoreboard__heading">
          <PcSpecialistLogo />
        </div>

        <div className="scoreboard__meta">
          <figure className="shapeTopLeft">
            <MetaCornerLeft />
          </figure>
          <figure className="shapeBottomRight">
            <MetaCornerRight />
          </figure>
          <div className="meta">
            <section>
              <h2>Player 1</h2>
            </section>
            <section />
            <section>
              <h2>Player 2</h2>
            </section>
          </div>
          <div className="scoreboard__shape">
            <ScoreJewl />

            <div className="meta__score">
              <p>{player1Score}</p> <p>:</p> <p>{player2Score}</p>
            </div>
          </div>
        </div>

        <div className="scoreboard__feed">
          <ul className="feed">
            {logs &&
              Object.values(logs)
                ?.slice(0, 4)
                .map((data: Events, index) => {
                  const player = data.player === "Player 1" ? 1 : 0;
                  const key = index;

                  return (
                    <li
                      key={key}
                      className={`feed__item ${
                        player === 1 ? "align-left" : "align-rigth"
                      }`}
                    >
                      <div className="item">
                        <img src={ScoreCorner} alt="" />
                        {player === 1 ? (
                          <BorderLeftSide />
                        ) : (
                          <BorderRightSide />
                        )}
                        <section className="item__info">
                          {player === 1 ? (
                            <>
                              <p>
                                {cs2Weapons[data.weaponName] || data.weaponName}{" "}
                              </p>
                              <div>
                                {data.wasHeadshot ? "Headshot" : "Kill"}
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                {data.wasHeadshot ? "Headshot" : "Kill"}
                              </div>
                              <p>
                                {cs2Weapons[data.weaponName] || data.weaponName}{" "}
                              </p>
                            </>
                          )}
                        </section>
                        <section className="item__time">
                          <time>{getTime(data.timestamp)}</time>
                        </section>
                      </div>
                    </li>
                  );
                })}
          </ul>
        </div>
      </div>
    </div>
  );
};
