export const getTime = (time) => {
  if (!time) {
    return "00:00";
  }

  const date = new Date(time * 1000);
  // console.log(date);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};
