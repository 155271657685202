import { useEffect } from "react";
import { TCL_ScoreBoard } from "./clients/TCL/Scoreboard";
import "./globalStyles/index.scss";
import { PCSPECIALIST_ScoreBoard } from "./clients/PCSPECIALIST/Scoreboard";
import { TCLLOGS_ScoreBoard } from "./clients/TCL-LOGS/Scoreboard";
function App() {
  const client = window.location.pathname;
  console.log(client.replace("/", " "));

  const scoreBoardToRender = {
    "/tcl": <TCL_ScoreBoard />,
    "/tcl-logs": <TCLLOGS_ScoreBoard />,
    "/pcspecialist": <PCSPECIALIST_ScoreBoard />,
  } as any;

  if (client.replace("/", " ").trim() === "") {
    return (
      <div className="app">
        <h1>No client found, /clientName missing</h1>
      </div>
    );
  }

  useEffect(() => {
    if (client === "/tcl") {
      document.title = "1v1 leaderboard TCL";
    }

    if (client === "/tcl-logs") {
      document.title = "1v1 leaderboard TCL LOGS";
    }

    if (client === "/pcspecialist") {
      document.title = "1v1 leaderboard PC-SPECIALIST";
    }
  }, [client]);

  return (
    <div className="app">
      {scoreBoardToRender[client] || "no scoreboard found with this name"}
    </div>
  );
}

export default App;
